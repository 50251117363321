<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="clientesLista"
          sort-by="nome"
          class="elevation-10"
          :search="search"
          :loading="!clientesProgress.length"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="ultima_compra"
          sortDesc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              <v-toolbar-title>Clientes da Loja</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-dialog v-model="dialog" max-width="500px"> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="recarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="selecionarClienteEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Cliente</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
              <!-- </v-dialog> -->
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.data_cadastro`]="{ item }">
            <span>{{ mixDataDBD(item.data_cadastro) }} </span>
          </template>
          <template v-slot:[`item.ultima_compra`]="{ item }">
            <span>{{ mixDataDBD(item.ultima_compra) }} </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="selecionarClienteEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="selecionarClienteCompraEdicao(item)"
                >
                  mdi-cart
                </v-icon>
              </template>
              <span>Compras</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("lojaClientes");

export default {
  components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "LojaClientesLista",
  data: () => ({
    listaCliAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id_cliente" },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome_cliente",
      },
      { text: "Cadastro", value: "data_cadastro", align: "start" },
      { text: "CPF", value: "cpf_cliente", align: "start" },
      { text: "Celular", value: "celular_cli", align: "start" },
      { text: "Ult. Compra", value: "ultima_compra", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["erro", "clientes", "clienteSelecionado"]),
    // ...mapGetters(["clientesAtivos", "clientesInativos"]),

    clientesLista() {
      return this.$store.state.lojaClientes.clientes;
    },
    clientesProgress() {
      return this.$store.state.lojaClientes.clientes;
    },
  },

  created() {
    register(this.$store);
    this.listarClientes(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterClientes();
  },

  methods: {
    ...mapActions([
      "deletarCliente",
      "listarClientes",
      "selecionarCliente",
      "resetarCliente",
      "ativarCliente",
    ]),

    ativoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    initialize() {
      /*       if (this.clientes === null) {
        this.clientes = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },
    novoCliente() {
      if (this.clienteSelecionado) {
        this.resetarClienteSelecionado();
        return;
      }
    },
    selecionarClienteCompraEdicao(cliente) {
      this.selecionarCliente({ cliente });
      this.$router.push({ name: "LojaClientesComprasForm" });
    },
    selecionarClienteEdicao(cliente) {
      this.selecionarCliente({ cliente });
      this.$router.push({ name: "LojaClientesForm" });
    },

    paraAtivacao(cliente) {
      // if (cliente.data_cadastro != "0000-00-00") {
      cliente.data_cadastro = this.formataDataIn(cliente.data_cadastro);
      // }
      // if (cliente.ultima_compra != "0000-00-00") {
      cliente.ultima_compra = this.formataDataIn(cliente.ultima_compra);
      // }
      this.ativarCliente({
        cliente: cliente,
        licenca: this.$store.state.login.licenca,
      });
    },
    resetarCliente() {
      this.resetarClienteSelecionado();
    },
    metVoltar() {
      this.$router.back();
    },
    recarregar() {
      this.listarClientes(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
